import "./Footer.scss";

function Footer() {
  return (
    <section className="footer">
      <div className="footer__container">
        <p className="footer__text">Connect with me:</p>
        <div className="footer__socials">
          <a href="mailto:oliviachu222@gmail.com" className="footer__link">
            Email
          </a>
          <a
            href="https://www.linkedin.com/in/viachu/"
            className="footer__link"
          >
            LinkedIn
          </a>
          <a href="https://github.com/viachiyu" className="footer__link">
            GitHub
          </a>
        </div>
        <p className="footer__tm">
          © 2024 Olivia Chu | All Rights Reserved - Made with ♡
        </p>
      </div>
    </section>
  );
}
export default Footer;
