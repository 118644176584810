import "./Project2.scss";
import BAIPHome from "../../Assets/Images/baip/BAIPHome.png";
import BAIP2 from "../../Assets/Images/baip/BAIP2.png";
import BAIPSolution from "../../Assets/Images/baip/BAIPSolution.png";

import GitHub from "../../Assets/Icons/github-mark.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Project2() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <article className="project2">
      <div className="project2__wrapper">
        <h2 className="project2__title">British Airways Hackathon</h2>

        <div className="project2__container">
          <div className="project2__left">
            <Slider {...settings}>
              <div>
                <img className="baip__image " src={BAIPHome} alt="screenshot" />
              </div>
              <div>
                <img className="baip__image" src={BAIP2} alt="screenshot" />
              </div>
              <div>
                <img
                  className="baip__image "
                  src={BAIPSolution}
                  alt="screenshot"
                />
              </div>
            </Slider>
          </div>

          <div className="project2__right">
            <div className="project2__text">
              <h3 className="project2__label">Description:</h3>
              <p className="project2__info">
                A 24 hour cross-discipline industry project by British Airways
                to develop a solution to the problem 'How might we encourage
                customers to book flights using their travel voucher?'
              </p>
            </div>
            <h3 className="project2__label">Tech Stack:</h3>
            <div className="project2__tags">
              <p className="project2__tag">React</p>
              <p className="project2__tag">HTML/CSS</p>
              <p className="project2__tag">SASS</p>
            </div>

            <a
              href="https://github.com/viachiyu/BA-industry-project-client"
              className="project2__github"
            >
              <img
                src={GitHub}
                alt="github icon"
                className="project2__ghicon"
              />
              <p className="project2__ghtext">GitHub</p>
            </a>
          </div>
        </div>
      </div>
    </article>
  );
}

export default Project2;
