import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Loading from "./Pages/Loading/loading";
import ProjectPage from "./Pages/ProjectPage/ProjectPage";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          {/* <Route path="/" element={<Loading />} /> */}
          <Route path="/" element={<ProjectPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
