import "./Projects.scss";
import PocketHome from "../../Assets/Images/pocket/pocket-home.png";
import PocketPockets from "../../Assets/Images/pocket/pocket-pockets.png";
import PocketGroup from "../../Assets/Images/pocket/pocket-group.png";
import PocketProfile from "../../Assets/Images/pocket/pocket-profile.png";
import PocketAdd from "../../Assets/Images/pocket/pocket-add.png";
import GitHub from "../../Assets/Icons/github-mark.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Projects() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <article className="project">
      <div className="project__wrapper">
        <h2 className="project__title">Pock/eT</h2>

        <div className="project__container">
          <div className="project__left">
            {/* <button className="project__button project__button--prev">
                  <img className="project__chevron" src={ChevronLeft} />
                </button> */}

            <Slider {...settings}>
              <div>
                <img
                  className="pocket__image "
                  src={PocketHome}
                  alt="screenshot"
                />
              </div>
              <div>
                <img
                  className="pocket__image"
                  src={PocketPockets}
                  alt="screenshot"
                />
              </div>
              <div>
                <img
                  className="pocket__image "
                  src={PocketGroup}
                  alt="screenshot"
                />
              </div>
              <div>
                <img
                  className="pocket__image "
                  src={PocketProfile}
                  alt="screenshot"
                />
              </div>
              <div>
                <img
                  className="pocket__image "
                  src={PocketAdd}
                  alt="screenshot"
                />
              </div>
            </Slider>

            {/* <button className="project__button project__button--next">
                  <img className="project__chevron " src={ChevronRight} />
                </button> */}
          </div>

          <div className="project__right">
            <div className="project__text">
              <h3 className="project__label">Description:</h3>
              <p className="project__info">
                A fullstack mobile-view webapp designed to effortlessly track
                and divide group expenses & settle up post-trip with ease.
              </p>
            </div>
            <h3 className="project__label">Tech Stack:</h3>
            <div className="project__tags">
              <p className="project__tag">React</p>
              <p className="project__tag">JavaScript</p>
              <p className="project__tag">HTML/CSS</p>
              <p className="project__tag">SASS</p>
              <p className="project__tag">Next.js</p>
              <p className="project__tag">Express</p>
              <p className="project__tag">Node.js</p>
              <p className="project__tag">MySQL</p>
              <p className="project__tag">Knex.js</p>
            </div>

            <a
              href="https://github.com/viachiyu/viachu-pocket-client"
              className="project__github"
            >
              <img src={GitHub} alt="github icon" className="project__ghicon" />
              <p className="project__ghtext">GitHub</p>
            </a>
          </div>
        </div>
      </div>
    </article>
  );
}
export default Projects;
