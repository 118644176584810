import "./Header.scss";
import { useEffect } from "react";

function Header() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <header className="header">
      <div className="header__wrapper">
        <button
          className="header__viachu"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          @viachu
        </button>
      </div>
    </header>
  );
}

export default Header;
