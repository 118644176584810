import "./ProjectPage.scss";
import Portrait from "../../Assets/Images/portrait.png";
import { useState } from "react";
import Projects from "../../Components/Projects/Projects";
import Project2 from "../../Components/Project2/Project2";

function ProjectPage() {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <main className="home">
      <div className="home__wrapper">
        <section className="intro">
          <div className="intro__container">
            <h1 className="intro__title">
              Hello, I'm <span className="intro__wavy">Olivia Chu</span>
            </h1>
            <img className="intro__mobportrait" src={Portrait} alt="me!" />
            <h3 className="intro__text">
              A Full-Stack Web Developer based in London.
            </h3>
          </div>
          <img className="intro__portrait" src={Portrait} alt="me!" />
        </section>
        <section className="about">
          <div className="about__wrapper">
            <h2 className="about__title">about me</h2>
            <p className="about__text">
              I'm Olivia, a web developer living in London with a passion for
              building things on the web using React, Next.js and Express.
              Usually found with a snack in hand, I strive to create simple yet
              aesthetic solutions for everyday life concerns. Aside from
              spending my time achieving pixel-perfect frontends, my hobbies
              include painting, pottery and cooking.
              <br></br>
              <br></br>
              While my background is in Law, my journey into tech officially
              begun once I completed a intensive 3-months long software
              engineering bootcamp. Here I discovered my love for replicating UX
              mockups using SASS and utilising Jira and Gitflow to work
              efficiently in a collaborative team. As a quick problem solver, I
              am always eager and excited to expand my skill set and contribute
              my expertise where needed.
            </p>
            <article className="about__table">
              <div className="about__tabs">
                <button
                  className={`about__tab
                ${toggleState === 1 ? "about__tab--active" : ""}`}
                  onClick={() => toggleTab(1)}
                >
                  Skills
                </button>
                <button
                  className={`about__tab
                ${toggleState === 2 ? "about__tab--active" : ""}`}
                  onClick={() => toggleTab(2)}
                >
                  Education
                </button>
              </div>
              <div className="about__contents">
                <div
                  className={`about__content
                ${toggleState === 1 ? "about__content--active" : ""}`}
                >
                  <p>HTML | CSS | SASS </p>
                  <p>React.js | Node.js | Express.js </p>
                  <p>DOM APIs | Web APIs </p>
                  <p> MySQL | Knex.js | MongoDB</p>
                  <p>Jira | Heroku | GitHub | Agile Development </p>
                </div>
                <div
                  className={`about__content
                ${toggleState === 2 ? "about__content--active" : ""}`}
                >
                  <p>- LLB Law [2:1] @ Aston University, Birmingham</p>
                  <p>
                    - Diploma in Software Engineering @ BrainStation, London
                  </p>
                </div>
              </div>
            </article>
          </div>
        </section>
        <section id="projects" className="projects">
          <div className="projects__wrapper">
            <h2 className="projects__title">my projects</h2>
            <Projects />
            <br />
            <Project2 />
          </div>
        </section>
      </div>
    </main>
  );
}

export default ProjectPage;
